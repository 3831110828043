/* Dropdown Button */
.dropdown-button {
  /* add button style if necessary */
  box-shadow: none;
}

/* Dropdown button on hover & focus */
.dropdown-button:hover,
.dropdown-button:focus {
  box-shadow: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  /* right: 0; */
  z-index: 1;
  padding: 10px 0px;
  min-width: 220px;
  background: #ffffff;
  border: 1px solid #dcdee0;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.128223);
}

.dropdown-content button {
  margin-left: 5px;
  box-shadow: none;
  text-decoration: underline;
  font-weight: 100;
}

.dropdown-content button:hover {
  box-shadow: none;
  text-decoration: underline;
}

.dropdown-content-icon {
  margin-right: 10px;
  vertical-align: middle;
}

.add-document-icon {
  margin-right: 10px;
}

button.attachment-delete-link {
  color: #b51d09;
}
.show {
  display: block;
}

button.action-button {
  margin-right: 0;
}

.usa-file-input {
  min-width: 562px;
}

.attachment-description {
  min-width: 560px;
}

.attachment-load-more {
  margin: 40px 0px;
}

.attachment-load-more-icon {
  margin-left: 10px;
}

.attachment-load-more-hide {
  display: none;
}

.table-header__date-uploaded {
  min-width: 170px;
}

.attachment-modal .modal-close-button {
  padding: 1rem;
}

.afp-attachment-table {
  table-layout: fixed;
  width: 100%;
}

.afp-attachment-table th,
.afp-attachment-table td {
  vertical-align: top;
  word-break: break-word;
}

.task-manager-updatedat {
  white-space: nowrap;
}

.task-manager-name {
  white-space: nowrap;
}

.task-manager-createdByUser {
  white-space: nowrap;
}

.task-manager-description {
  white-space: nowrap;
}
