.create-account-modal {
  .title {
    font-size: 24px;
    margin-bottom: 40px;
    font-weight: bold;
  }

  .account-options {
    display: flex;
    flex-direction: row;
    background: rgba(239, 246, 251, 1);
    justify-content: space-between;
    gap: 30px;
    padding: 40px;
    margin-bottom: 20px;

    .option {
      border-right: 1px solid rgb(175, 199, 216);
      padding-right: 30px;
      width: 33%;
      display: flex;
      flex-direction: column;

      .icon {
        background: rgba(26, 68, 128, 1);
        border-radius: 50%;
        width: 70px;
        height: 70px;
        text-align: center;
        padding-top: 20px;

        .img {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 30px;
        }
      }

      h3 {
        font-size: 18px;
        margin-bottom: 10px;
        color: #0071bc;
      }

      p {
        font-size: 14px;
        color: #555;
        margin-bottom: 20px;
      }
    }

    .sidebar {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: 30%;
      font-size: 14px;
      color: #555;

      strong {
        color: #000;
      }

      a {
        color: #0071bc;
        text-decoration: underline;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .cancel-button {
    display: block;
    margin: 20px auto 0;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    .account-options {
      flex-direction: column; // Change direction to column
      gap: 20px; // Adjust gap between options
      padding: 20px; // Adjust padding for smaller screens

      .option {
        border-right: none; // Remove the right border for individual options
        padding-right: 0; // Remove extra padding
        width: 100%; // Set width to full for stacking
        text-align: start;
      }

      .icon{
        display: none;
      }
    }

    .title {
      font-size: 20px; // Slightly smaller font size for the title
    }

    .cancel-button {
      font-size: 14px; // Adjust font size for smaller screens
    }
    .sidebar{
      width: 100% !important;
      text-align: start;
    }
  }
}
