.afp-task__details-table {
  width: 100%;
  border-collapse: collapse;
}

.afp-task__details-table th,
.afp-task__details-table td {
  padding: 0.5rem 0;
}

.afp-task-detail__title_tags > * {
  margin-left: 2rem;
  white-space: nowrap;
}

.bg-base-lighter {
  background: #f0f0f0;
}

.afp-task-status-tag {
  margin-right: 2rem;
}

.afp-task-detail__task_detail_links {
  margin-top: 2rem;
}

.afp-task-detail__task_detail_links a {
  margin-right: 3rem;
}

.afp-task-detail .afp-details-table {
  width: unset;
}

.afp-task-detail .afp-details-table td {
  padding-left: 2rem;
}

.afp-task-detail__inline_container * {
  display: inline-block;
  margin-right: 0.5rem;
}

.afp-task-detail__task_description_text {
  font-size: 1.5rem;
}

.afp-task-detail h1 {
  font-size: 3rem !important;
}

.afp-task-detail .afp-details-table tr,
.afp-task-details__side_col .afp-details-table tr {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.afp-task-detail .afp-details-table tr {
  border-bottom: 1px solid rgb(202, 202, 202);
}

.afp-task-details__side_col .afp-details-table tr {
  border-bottom: 1px solid rgb(234, 235, 236);
}

.afp-task-detail .afp-details-table tr td,
.afp-task-details__side_col .afp-details-table tr td {
  text-align: right;
}

.afp-underlined-text {
  /* text-decoration: underline; */
}

.afp-task-detail__recall_campaign {
  /* color: rgb(31, 79, 131); */
}

.afp-task-detail .afp-task-status-tag {
  background: white;
}
