.divider {
  display: flex;
  align-items: center;
  text-align: center;
  /* width: 433px; */
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 2px solid #dcdee0;
}

.divider:not(:empty)::before {
  margin-right: 0.25em;
}

.divider:not(:empty)::after {
  margin-left: 0.25em;
}

.loading_backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
}

.max-w-none {
  max-width: none;
}

.contact-information {
  font-size: 16px;
  font-weight: 900;
  line-height: 150%;
  letter-spacing: 1px;
}

.icon-container {
  background-color: var(--Fleet-primary-dark, #1a4480);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-top: 2rem;
  svg {
    width: 40px;
    height: 40px;
    fill: white;
  }
}

.signup-page {
  h2,
  strong {
    color: #162e51;
  }
}
