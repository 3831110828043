.modal {
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  max-width: 60vw;
  width: 50vw;
  max-height: calc(100vh - 4rem);
  overflow-y: auto;
  padding: 0px;
}

.modal .content {
  margin: 0 20px 20px;
  overflow-y: auto;
}

.modal .title {
  margin-top: 40px;
  margin-left: 20px;
  font-weight: bold;
}

.modal .actions {
  padding: 20px 30px;
  background-color: #f0f0f0;
}

.modalClose {
  margin-top: 10px;
  margin-right: 10px;
}
