.modal--lg {
  width: 60vw;
}
.modal--lg .usa-button--unstyled {
  text-decoration: none;
}
.modal--lg .content {
  overflow: unset;
}

.modal--lg .content .section-minor-header {
  font-weight: bold;
  display: block;
}
