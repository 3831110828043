.successful-header {
  max-width: 36rem;
  line-height: 3.85rem;
}

.successful-text {
  max-width: 35.71rem;
  line-height: 1.85rem;
}


.citystate {
  display: flex;
  justify-content: space-between;
}

.alert {
  min-height: 9.14rem;
}

.state {
  width: 15.07rem !important;
}

.validation-hint {
  color: #71767a;
  font-size: 1.14rem;
  font-weight: 400;
  line-height: 1.85rem;
}

.postalCode {
  max-width: 10.71rem !important;
}

.loading_backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
}

@media only screen and (max-width: 480px) {
  .citystate {
    display: block;
  }
}


.standard-readonly {
  color: #71767a;
}

.standard-separator {
  border-top: 1px solid #dcdee0;
}
