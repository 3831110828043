.text-filter-container button {
  right: 1rem !important;
}

.table-filters td {
  background: #eff6fb !important;
}

.task-manager__title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 16rem;
}

.task-manager {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.task-manager::-webkit-scrollbar {
  display: none;
}

.task-manager th,
.task-manager td {
  padding-right: 0 !important;
}

.task-manager .task-manager__tagNumber,
.task-manager .task-manager__inventoryStatus {
  max-width: 8rem;
}

.task-manager .task-manager__customerNumber {
  max-width: 8rem !important;
}

.task-manager .task-manager__taskStatus {
  width: 7rem;
}

.task-manager button {
  font-size: 1rem;
  white-space: nowrap;
}

.task-manager .afp-pagination {
  margin: 3rem 0 6rem 0;
}

@keyframes moving-gradient {
  0% {
    background-position: -250px 0;
  }
  100% {
    background-position: 250px 0;
  }
}

.task-manager .data-loading {
  display: inline-block;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
  background-size: 500px 100px;
  animation-name: moving-gradient;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.task-manager__filtered-table {
  display: flex;
}

.task-manager__filtered-table .usa-table {
  margin-top: 0;
}

.task-manager__filtered-table .task-manager__filter-col {
  padding-top: 1.5rem;
  margin-right: 2rem;
  width: 13rem;
}

.task-manager .usa-accordion__button {
  background-size: 0.7rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.task-manager .no-tasks-message h2 {
  margin: 0 !important;
  padding-top: 1rem;
  font-weight: 400;
}

.afp-table-header__sort-button {
  padding: 0;
}

.afp-task-detail__task_detail_links svg {
  margin-left: 0.3rem;
  transform: translateY(0.1rem);
}
.afp-task-detail__inline_container {
  .afp-details-table {
    table-layout: fixed;
    width: 100%;
  }

  .afp-details-table tr {
    display: block;
  }

  .afp-details-table tr th {
    padding-right: 1rem;
    width: 11.5rem;
  }
}

.afp-task-manager__subsection_title {
  font-weight: 900;
  color: rgb(31, 79, 131);
  font-family: inherit;
  letter-spacing: 1px;
}
