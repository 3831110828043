.user-header {
    .user-info-row.grid-row>div:after {
        content: '';
        align-content: center;
        position: absolute;
        width: 1px;
        height: 40px;
        background: #DFE1E2;
        top: 12px;
        right: 0px;
    }

    .user-info-row.grid-row>div:last-child:after {
        display: none;
    }
}