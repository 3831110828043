.documentation-listing {
    .afp-modal.large {
        width: 550px;
    }

    .usa-file-input {
        min-width: 48px
    }

    .top-padding,
    .file-description {
        padding-top: 30px;
    }

    .pii-label {
        text-decoration: underline;
        color: #005EA2;
    }

    td.cell-center {
        width: 50px;
        min-width: 113px;
    }

    .upload-modal {
        display: flex;
        justify-content: center;
        align-items: center;
      }
}

