.buttons-container {
    display: flex;
    gap: 30px;
    margin-bottom: 300px;
    flex-wrap: wrap;

    .purchase-click-button {
      width: 450px;
      height: 192px;
      gap: 24px;
      border-radius: 8px;
      text-align: left;
      background: var(--Fleet-blue-5, #EFF6FB) ;
      border: none;
      line-height: 162%;
    }

    .purchase-click-button:hover:enabled {
      background: var(--Fleet-primary, #005EA2);
      color: #ffffff;
      cursor: pointer;

      .button-titles, .button_text {
        color: #ffffff;
      }

      span {
        color: #ffffff;
      }
    }

    .button-titles {
      color: var(--Fleet-primary, #005EA2);
      font-size: 24px;
      font-weight: 400;
    }

    .button_text{
        color: var(--Fleet-primary, #1B1B1B);
        font-size: 16px;
        font-weight: 400;
    }
  }

  .agency-documentation {
    padding-top: 40px;
    display: flex;
    justify-content: space-between;

    .attachments-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 32.4px;
      }
  }

    .agency-dropdown-label {
      font-size: 24px;
      font-weight: 400;
      line-height: 32.4px;
      padding-top:20px;
    }