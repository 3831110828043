.app {
  #main-content a {
    color: #005ea2 !important;
  }
  
  #main-content .white-anchor-text {
    color: white !important;
    white-space: nowrap;
  }
}
