.login-redirect_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    text-align:center;
    background: #FFFFFF;
    opacity: .8;
  }

  .login-redirect_spinner{
    position: relative;
    top: 40%;
    width: 100vw;
    z-index: 1000;
  }